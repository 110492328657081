<template>
    <v-app id="inspire">
        <v-app-bar app style="background:white;">
            <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
            <a href="https://shop.youandi.at/"><img :src="require('../assets/logo.jpg')" class="h-10 mr-5"></a>
            <v-toolbar-title class="d-none d-sm-block">Kalorienrechner</v-toolbar-title>
            <a class="float-right absolute right-5" href="https://shop.youandi.at/"><v-icon color="green" large>mdi-cart</v-icon></a>
        </v-app-bar>

        <v-navigation-drawer
            v-model="drawer"
            fixed
            temporary
            class="p-5"
            >
            <v-app-bar-nav-icon @click="drawer = !drawer" class="-mt-1 mr-3"></v-app-bar-nav-icon>
            <a href="https://youandi.at/"><span class="text-green-700">You and I</span></a>
            <v-container cols="12" class="space-y-7 pt-6 text-black">
                <a href="https://youandi.at/" class="block"><v-icon class="-mt-1 mr-2">mdi-home</v-icon>Homepage</a>
                <a href="https://shop.youandi.at/" class="block"><v-icon class="-mt-1 mr-2">mdi-cart</v-icon>Shop</a>
                <hr>
                <a href="https://shop.youandi.at/impressum/" class="block"><v-icon class="-mt-1 mr-2">mdi-script-text-outline</v-icon>Impressum</a>
                <a href="https://shop.youandi.at/widerrufsbelehrung/" class="block"><v-icon class="-mt-1 mr-2">mdi-information-outline</v-icon>Widerrufsbelehrung</a>
                <a href="https://shop.youandi.at/agb/" class="block"><v-icon class="-mt-1 mr-2">mdi-mail</v-icon>AGB</a>
                <a href="https://shop.youandi.at/datenschutzerklaerung/" class="block"><v-icon class="-mt-1 mr-2">mdi-email</v-icon>Datenschutzerklärung</a>
            </v-container>
        </v-navigation-drawer>

        <v-main class="mb-20">
            <Rechner />
        </v-main>
    </v-app>
</template>

<script>
import Rechner from "./Rechner.vue"

  export default{
    components: { 
      Rechner,
    },
    data: () => ({ drawer: null }),
  }
</script>

<style>
    .theme--light.v-app-bar.v-toolbar.v-sheet{
        background:white;
    }
    .v-application a{
        color:rgb(93, 93, 93) !important;
    }
</style>
<template>
  <div id="app">
    <Nav />
    <Rechner />
  </div>
</template>

<script>
import Nav from './components/Nav.vue';

export default {
  name: 'app',
  components: {
    Nav,
  },
  data(){
    return {
      kalorien:1296,
    }
  }
}
</script>

<style>
  * {
    box-sizing: border-box;
  }
  body {
    font-family: 'Ubuntu', serif;
  }
  h1{
    font-size:2em;
  }
  h2{
    font-size:1.6em;
    text-decoration: underline;
  }
</style> 

<template>
    <div class="md:mt-5 max-w-4xl mx-auto p-10 lg:shadow-2xl rounded-2xl lg:border-2 lg:border-green-600 border-solid">
        <v-form ref="form">
            <v-row>
                    <h1>Kalorienrechner</h1>
                    <p class="pt-5">
                        Mit meinem Online Rechner kannst du bequem deinen Kalorienverbrauch berechnen, der anhand der von dir eingetragenen Stunden an Aktivitäten kalkuliert wird.
                    </p>
            </v-row>
            <v-row>
                <h2>Details zu deiner Person</h2>
            </v-row>
            <v-row>
                <v-col cols="12" sm="6" xs="8">
                    
                    <v-radio-group v-model="gender" row>
                        <v-radio :name="gender" label="Männlich" value="0"></v-radio>
                        <v-radio :name="gender" label="Weiblich" value="1"></v-radio>
                    </v-radio-group>
                </v-col>
                <v-col>
                    <v-slider
                        v-model="gewicht"
                        label="Gewicht"
                        :thumb-color="slider.color"
                        :thumb-size="20"
                        :track-color="slider.open"
                        :color="slider.track"
                        thumb-label="always"
                        min="30"
                        max="200"
                        class="mt-4"
                    ></v-slider>
                </v-col>
                <v-col cols="12">
                    <div class="text-xl">Dein Grundumsatz: 
                        <span class="
                                    inline-block 
                                    rounded-md 
                                    p-2 
                                    pb-1
                                    block
                                    m-1
                                    text-white
                                    text-center
                                    w-full
                                    md:w-auto
                                    lg:w-auto
                                    xl:w-auto
                                    border-b-4 
                                    border-green-700 
                                    bg-green-600 
                                    hover:bg-green-700 
                                    select-none
                                    hover:border-green-800">{{ grundumsatz }} kcal
                            </span>
                    </div>
                </v-col>
            </v-row>
            <v-row class="pb-5">
                <v-col cols="12" md="12">
                    <span class="block h-1 bg-green-600"></span>
                </v-col>
            </v-row>
            <v-row>
                <h2>Details zu deinem Tagesablauf</h2>
                <div class="block w-full">
                    Gib uns eine kurze Übersicht, wie du deinen Tag verbringst. 
                </div>
                <div class="pb-4" :class="color">{{ gesamtstunden }} von 24 Stunden verplant</div>
            </v-row>
            <v-row>
                <v-col cols="12" md="4" sm="6">
                    <v-slider
                        v-model="leistung_schlafen"
                        hint="Schlafend"
                        persistent-hint
                        :thumb-size="20"
                        :thumb-color="slider.color"
                        :track-color="slider.open"
                        :color="slider.track"
                        :rules="rules"
                        thumb-label="always"
                        min="0"
                        max="24"
                        @input="validate()"
                    ></v-slider>
                </v-col>
                <v-col cols="12" md="4" sm="6">
                    <v-slider
                        v-model="leistung_liegend"
                        hint="Liegend"
                        persistent-hint
                        :thumb-size="20"
                        :thumb-color="slider.color"
                        :track-color="slider.open"
                        :color="slider.track"
                        :rules="rules"
                        thumb-label="always"
                        min="0"
                        max="24"
                        @input="validate()"
                    ></v-slider>
                </v-col>
                <v-col cols="12" md="4" sm="6">
                    <v-slider
                        v-model="leistung_sitzend"
                        hint="Sitzend"
                        persistent-hint
                        :thumb-size="20"
                        :thumb-color="slider.color"
                        :track-color="slider.open"
                        :color="slider.track"
                        :rules="rules"
                        thumb-label="always"
                        min="0"
                        max="24"
                        @input="validate()"
                    ></v-slider>
                </v-col>
                <v-col cols="12" md="6" sm="6">
                    <v-slider
                        v-model="leistung_stehend"
                        hint="Stehend"
                        persistent-hint
                        :thumb-size="20"
                        :thumb-color="slider.color"
                        :track-color="slider.open"
                        :color="slider.track"
                        :rules="rules"
                        thumb-label="always"
                        min="0"
                        max="24"
                        @input="validate()"
                    ></v-slider>
                </v-col>
                <v-col cols="12" md="6" sm="12">
                    <v-slider
                        v-model="leistung_sport"
                        hint="Sport"
                        persistent-hint
                        :thumb-size="20"
                        :thumb-color="slider.color"
                        :track-color="slider.open"
                        :color="slider.track"
                        :rules="rules"
                        thumb-label="always"
                        min="0"
                        max="24"
                        @input="validate()"
                    ></v-slider>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <div class="text-xl">Dein täglicher Kalorienbedarf: 
                        <span class="
                                    inline-block 
                                    rounded-md 
                                    p-2 
                                    pb-1
                                    block
                                    m-1
                                    mb-5
                                    text-white
                                    text-center
                                    w-full
                                    md:w-auto
                                    lg:w-auto
                                    xl:w-auto
                                    border-b-4 
                                    border-green-700 
                                    bg-green-600 
                                    hover:bg-green-700 
                                    select-none
                                    hover:border-green-800">{{ kalorien }} kcal
                            </span>
                    </div>
                    <br>

                    <h2>Nächste Schritte:</h2>
                    <div class="m-auto md:flex justify-center sm:w-3/4 md:w-full">
                        <v-card elevation="2" outlined class="m-1 w-full">
                            <div class="flex md:block lg:flex">
                                <div class="ml-5 mt-4 text-3xl">1.</div>
                                <div>
                                    <v-card-title><v-icon color="black" class="mr-2">mdi-shopping</v-icon>Programme</v-card-title>
                                    <v-card-text>Fitter werden mit meinen Online Kursen</v-card-text>
                                    <div class="md:absolute bottom-2 block">
                                        <v-btn text color="green accent-5" onclick="window.location.href = 'https://www.youandi.at/';">Website</v-btn>
                                        <v-btn text color="green accent-5" onclick="window.location.href = 'https://shop.youandi.at/';">Shop</v-btn>
                                    </div>
                                </div>
                            </div>
                        </v-card>
                        <v-card elevation="2" outlined class="m-1 w-full">
                            <div class="flex md:block lg:flex pb-2">
                                <div class="ml-5 mt-4 text-3xl">2.</div>
                                <div>
                                    <v-card-title><v-icon color="black" class="mr-2">mdi-arm-flex</v-icon>Indivduell</v-card-title>
                                    <v-card-text>Du möchtest den optimalen Trainingsplan? Kontaktiere mich für eine individuelle Beratung</v-card-text>
                                    <v-btn text color="green accent-5" href="https://api.whatsapp.com/send?phone=00436764622239" title="00436764622239">Whatsapp</v-btn>
                                    <v-btn text color="green accent-5" href= "mailto:sebastian@youandi.at" title="sebastian@youandi.at">Email</v-btn>
                                </div>
                            </div>
                        </v-card>
                        <v-card elevation="2" outlined class="m-1 w-full">
                            <div class="flex md:block lg:flex">
                                <div class="ml-5 mt-4 text-3xl">3.</div>
                                <div>
                                    <v-card-title><v-icon color="black" class="mr-2">mdi-restart</v-icon>Neustarten</v-card-title>
                                    <v-card-text>Kalorienrechner zurücksetzen und von vorne starten</v-card-text>
                                    <div class="md:absolute bottom-2 block"><v-btn text color="green accent-5" @click="reset()">Zurücksetzen</v-btn></div>
                                </div>
                            </div>
                        </v-card>
                    </div>
                </v-col>
            </v-row>
        </v-form>


        <v-col
        class="text-center fixed bottom-0 left-0 bg-gray-300"
        cols="12"
        >
            <div class="d-block d-sm-none d-md-none text-xl text-center">
                Dein Kalorienbedarf: <span class="bg-green-600 text-white p-1 px-3 br-3 rounded-lg m-auto inline-block">{{ kalorien }} kcal</span>
            </div>
            <span class="d-none d-sm-block">{{ new Date().getFullYear() }} — <strong>©️ youandi</strong></span>
        </v-col>
    
    </div>
</template>

<script>
    export default {
        name: "Rechner",
        data(){
            return this.initialState();
        },
        computed:  {
            pal(){return ((this.leistung_schlafen*0.95) + (this.leistung_liegend*1.2) + (this.leistung_sitzend*1.5) + (this.leistung_stehend*1.8) +  (this.leistung_sport*2.2)) / 24;},
            grundumsatz(){
                switch (this.gender) {
                    case '1':
                        return Math.ceil(this.gewicht*.9*24)
                    default:
                        return Math.ceil(this.gewicht*1*24)
                }
            },
            gesamtstunden(){
                return ((this.leistung_schlafen) + (this.leistung_liegend) + (this.leistung_sitzend) + (this.leistung_stehend) +  (this.leistung_sport));},
            verbleidendeStunden(){
                return 24-((this.leistung_schlafen) + (this.leistung_liegend) + (this.leistung_sitzend) + (this.leistung_stehend) +  (this.leistung_sport));    
            },
            kalorien(){
                
                var tmp = this.pal * this.grundumsatz;
                this.$emit('p-kalorien', Math.ceil(tmp));
                return Math.ceil(tmp);
            },
            color () {
                if (this.verbleidendeStunden > 0) return 'text-yellow-600'
                if (this.verbleidendeStunden === 0) return 'text-green-600'
                if (this.verbleidendeStunden < 0) return 'text-red-500'
                return 'text-red-500'
            },
        },
        methods:{
            validate () {
                this.$refs.form.validate()
            },
            reset(){
                Object.assign(this.$data, this.initialState());
            },
            initialState (){
                return{
                    gender:"0",
                    gewicht:60,
                    leistung_sport: null,
                    leistung_liegend: null,
                    leistung_sitzend: 2,
                    leistung_stehend: null,
                    leistung_schlafen: 8,
                    slider: {open:'rgba(5,150,10,.3)', track:'rgba(5,150,10,1)',color:'rgba(5,150,10,1)'},
                    rules: [
                        v => v <= this.verbleidendeStunden + v || 'Dein Tag besteht aus mehr als 24 Stunden',
                    ],
                }
            },
        }
    }
</script>

<style>
    v-slider{
        thumb-size:"18";
    }
</style>